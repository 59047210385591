<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="">
        <label class="form__label">Organization Logo</label> <br />
        <div class="row mr-5 h-100 align-items-center">
          <div class="form__item col-md-8 text-center">
            <!-- <div class="image-box">
              <ion-icon
                class="icon"
                v-if="!isFavFile && !orgBranding.iconImage"
                name="image-outline"
              ></ion-icon>
              <img
                :src="favImg"
                alt="logo"
                v-if="isFavFile"
                class="logoPreview"
                srcset=""
              />
              <img
                :src="orgBranding.iconImage | base64ToImg"
                alt="logo"
                v-if="isFavFile && orgBranding.iconImage"
                class="logoPreview"
                srcset=""
              />
            </div> -->
            <!-- <input
              id="logoFile"
              type="file"
              accept="image/*"
              @change="onLogoFileChange($event)"
              hidden
            /> -->
            <div class="image-box">
              <ion-icon
                class="icon"
                v-if="!isFavFile && !orgBranding.iconImage"
                name="image-outline"
              ></ion-icon>
              <img
                :src="favImg"
                alt="fav"
                v-if="isFavFile"
                class="logoPreview"
                srcset=""
              />
              <img
                :src="orgBranding.iconImage | base64ToImg"
                alt="fav"
                v-if="!isFavFile && orgBranding.iconImage"
                class="logoPreview"
                srcset=""
              />
            </div>
            <input
              id="favFile"
              type="file"
              accept="image/*"
              @change="onFavFileChange($event)"
              hidden
            />
          </div>

          <div class="form__item col-md-4 text-left">
            <button
              class="button form__button form__button--lg mb-3"
              type="file"
              id="myfile"
              name="myfile"
              @click="callFileUpload('favFile')"
              v-if="!isFavFile"
            >
              Click to Upload
              <ion-icon class="ml-3" name="expand-outline"></ion-icon>
            </button>
            <button
              class="button form__button form__button--lg mb-3"
              type="file"
              id="myfile"
              name="myfile"
              @click="uploadLogo"
              v-if="isFavFile && !isUploadingFav"
            >
              Save
              <i class="ki ki-long-arrow-down icon-sm ml-3"> </i>
            </button>
            <!-- loader button -->
            <a
              role="button"
              class="
                button
                form__button form__button--lg
                mb-3
                w-25
                save-changes
              "
              v-if="isUploadingFav"
              :disabled="true"
            >
              <div class="spinner-border text-light" role="status"></div>
            </a>
            <button
              class="button form__button form__button--lg mb-3 btn-danger"
              type="file"
              id="myfile"
              name="myfile"
              @click="removeFavFile()"
              v-if="isFavFile"
            >
              Remove Image
              <ion-icon class="ml-3" name="trash-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <!-- <hr class="dashed"> -->
      <div class="mt-5">
        <label class="form__label">Organization Fav ICON</label> <br />
        <div class="row mr-5 h-100 align-items-center">
          <div class="form__item col-md-8 text-center">
            <div class="image-box2">
              <ion-icon
                class="icon"
                v-if="!isFavFile && !orgBranding.iconImage"
                name="image-outline"
              ></ion-icon>
              <img
                :src="favImg"
                alt="fav"
                v-if="isFavFile"
                class="logoPreview"
                srcset=""
              />
              <img
                :src="orgBranding.iconImage | base64ToImg"
                alt="fav"
                v-if="!isFavFile && orgBranding.iconImage"
                class="logoPreview"
                srcset=""
              />
            </div>
            <input
              id="favFile"
              type="file"
              accept="image/*"
              @change="onFavFileChange($event)"
              hidden
            />
          </div>
          <div class="form__item col-md-4 text-left">
            <button
              class="button form__button form__button--lg mb-3"
              type="file"
              id="myfile"
              name="myfile"
              @click="callFileUpload('favFile')"
              v-if="!isFavFile"
            >
              Click to Upload
              <ion-icon class="ml-3" name="expand-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import "boxicons";
import organisationBrandingService from "@/core/services/general-setup-service/organisation-branding.service.js";
export default Vue.extend({
  name: "organization-branding",
  data: function () {
    return {
      content_header: {
        title: "Branding",
        subTitle: "",
        // subTitle:
        //   "Set up your organizations's logo and favicon to show on documents and other relevant places.",
      },
      isLoading: false,
      isUploadingLogo: false,
      isUploadingFav: false,
      orgBranding: {
        iconImage: null,
        logoImage: null,
      },
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      logoFileData: null,
      isLogoFile: false,
      logoImg: null,
      favFileData: null,
      isFavFile: false,
      favImg: null,
    };
  },
  methods: {
    async getOrgBranding() {
      try {
        this.isLoading = true;
        const res = await organisationBrandingService.get();
        if (res.data.data != null) {
          this.orgBranding = res.data.data;
        }
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    saveForm: async function (e) {
      e.preventDefault();
      try {
        this.isLoading = true;
        await organisationBrandingService.update(this.orgBranding);
        this.alertSuccess("Image uploaded.");
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    callFileUpload(id) {
      let input = document.getElementById(id);
      input.click();
    },
    onLogoFileChange(ev) {
      this.logoFileData = ev.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.logoFileData);
      reader.onload = (e) => {
        this.logoImg = e.target.result;
        this.isLogoFile = true;
      };
    },
    onFavFileChange(ev) {
      this.favFileData = ev.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.favFileData);
      reader.onload = (e) => {
        this.favImg = e.target.result;
        this.isFavFile = true;
      };
    },
    removeLogoFile() {
      this.isLogoFile = false;
      this.logoFileData = null;
      this.logoImg = null;
    },
    removeFavFile() {
      this.isFavFile = false;
      this.favFileData = null;
      this.favImg = null;
    },
    reloadLogo: function () {
      this.$root.$emit("image_uploaded");
    },
    // uploadLogo: async function() {
    //   try {
    //     this.isUploadingLogo = true;
    //     const image = new FormData();
    //     image.append("name", "organizationLogo");
    //     image.append("LogoImage", this.logoFileData);
    //     await organisationBrandingService.update(image);
    //     this.alertSuccess("Logo uploaded.");
    //     this.getOrgBranding();
    //     this.reloadLogo();
    //     this.removeLogoFile();
    //     this.isUploadingLogo = false;
    //   } catch (e) {
    //     this.isUploadingLogo = false;
    //     const error = e.response.data.message;
    //     this.alertError(error);
    //   }
    // },
    uploadLogo: async function () {
      try {
        this.isUploadingFav = true;
        const image = new FormData();
        image.append("name", "organizationIcon");
        image.append("iconImage", this.favFileData);
        await organisationBrandingService.update(image);
        this.alertSuccess("Icon uploaded.");
        this.getOrgBranding();
        this.removeFavFile();
        this.$root.$emit("image_uploaded");
        this.isUploadingFav = false;
      } catch (e) {
        this.isUploadingFav = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getOrgBranding();
  },
  filters: {
    base64ToImg: function (value) {
      const imgUrl = "data:image/jpg;base64," + value;
      return imgUrl;
    },
  },
});
</script>

<style scoped>
.image-box {
  position: relative;
  height: 170px;
  width: 170px;
  border-radius: 15px;
  background-color: #fff;
}
.image-box .icon {
  color: #808191;
  font-size: 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.image-box .logoPreview,
.image-box2 .logoPreview {
  max-width: 100%;
  max-height: 100%;
}
.image-box2 {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 15px;
  background-color: #fff;
}
.image-box2 .icon {
  color: #808191;
  font-size: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
