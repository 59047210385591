import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "OrganisationBranding";
const organisationBrandingService = {
  get() {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, "");
    }
  },

  update(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(resource, data);
    }
  },
};

export default organisationBrandingService;
